import * as React from 'react'
import { FormattedDateTimeVm } from './formatted-datetime-vm'

export type FormattedDateTimeProps = {
  date: Date | string
  /**
   * Whether to show as a relative time string, or a fixed formatted date
   * @example <FormattedDateTime variant="relative" />
   */
  variant?: 'relative' | 'fixed'
  className?: string
}

export const FormattedDateTime: React.VFC<FormattedDateTimeProps> = (props) => {
  const { date, className, variant = 'relative' } = props

  const vm = new FormattedDateTimeVm(date)

  return (
    <time className={className} dateTime={vm.dateTime}>
      {variant === 'relative' && vm.relativeDateTime}
      {variant === 'fixed' && vm.fixedDateTime}
    </time>
  )
}
