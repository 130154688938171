import { differenceInCalendarDays, format } from 'date-fns'

export class FormattedDateTimeVm {
  private date: Date

  constructor(date: string | Date) {
    this.date = date instanceof Date ? date : new Date(date)
  }

  get fixedDateTime() {
    return format(this.date, 'dd MMMM yyyy')
  }

  get relativeDateTime() {
    const difference = differenceInCalendarDays(new Date(), this.date)
    if (difference === 0) {
      return format(this.date, "'Today at' h:mm a")
    } else if (difference === 1) {
      return 'Yesterday'
    } else if (difference > 0) {
      return `${difference} days ago`
    } else if (difference === -1) {
      return 'Tomorrow'
    } else {
      return `In ${-difference} days`
    }
  }

  /** Used for the <time> tag dateTime prop */
  get dateTime() {
    return format(this.date, 'yyyy-MM-dd')
  }
}
